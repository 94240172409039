import React, { lazy } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import "./App.css";
import Loader from "./components/Loader";
import ScrollToTop from "./hooks/ScrollToTop";

const Navbar = lazy(() => import("./layout/Navbar"));
const Footer = lazy(() => import("./layout/Footer"));
const Home = lazy(() => import("./pages/home/Home"));
const RiskAnalysis = lazy(() => import("./pages/risk analysis/RiskAnalysis"));
const Predicta = lazy(() => import("./pages/predicta/Predicta"));
const PredictionHealthFactors = lazy(() =>
  import("./pages/prediction health factors/PredictionHealthFactors")
);
const AutomatedCoaching = lazy(() =>
  import("./pages/automated coaching/AutomatedCoaching")
);
const Contact = lazy(() => import("./pages/contact/Contact"));
const Formations = lazy(() => import("./pages/formations/Formations"));
const AboutUs = lazy(() => import("./pages/about us/AboutUs"));
const DownloadFirstGuide = lazy(() =>
  import("./pages/download guide/DownloadFirstGuide")
);
const DownloadSecondGuide = lazy(() =>
  import("./pages/download guide/DownloadSecondGuide")
);
const FormationGeste = lazy(() =>
  import("./pages/formations/pages/FormationGeste")
);
const FormationTms = lazy(() =>
  import("./pages/formations/pages/FormationTms")
);
const FormationNeuroscience = lazy(() =>
  import("./pages/formations/pages/FormationNeuroscience")
);
const LivresBlocs = lazy(() => import("./pages/livres blocs/LivresBlocs"));
const ScientificLiterature = lazy(() =>
  import("./pages/scientific literature/ScientificLiterature")
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});

const App = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <>
          <BrowserRouter>
            <React.Suspense fallback={<Loader />}>
              <ScrollToTop />
              <Navbar />
              <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route
                  exact
                  path="/produit/logiciel-predicta"
                  element={<Predicta/>}
                />
                <Route
                  exact
                  path="/produit/analyse-des-risques"
                  element={<RiskAnalysis/>}
                />
                <Route
                  exact
                  path="/produit/prediction-des-facteurs-de-santé"
                  element={<PredictionHealthFactors/>}
                />
                <Route
                  exact
                  path="/produit/accompagnement-automatisé"
                  element={<AutomatedCoaching/>}
                />
                <Route exact path="/contact" element={<Contact/>} />
                <Route exact path="/formations" element={<Formations/>} />
                <Route exact path="/about-us" element={<AboutUs/>} />
                <Route
                  exact
                  path="/télécharger-premier-guide"
                  element={<DownloadFirstGuide/>}
                />
                <Route
                  exact
                  path="/télécharger-deuxième-guide"
                  element={<DownloadSecondGuide/>}
                />
                <Route
                  exact
                  path="/formations/tms"
                  element={<FormationTms/>}
                />
                <Route
                  exact
                  path="/formations/geste"
                  element={<FormationGeste/>}
                />
                <Route
                  exact
                  path="/formations/neuroscience"
                  element={<FormationNeuroscience/>}
                />
                <Route
                  exact
                  path="/ressources/livres-blancs"
                  element={<LivresBlocs/>}
                />
                <Route
                  exact
                  path="/ressources/littérature-scientifique"
                  element={<ScientificLiterature/>}
                />
              </Routes>
              <Footer />
            </React.Suspense>
          </BrowserRouter>
        </>

        <ReactQueryDevtools position="bottom-right" />
      </QueryClientProvider>
    </>
  );
}

export default App;
